/* * {
    border: 0;
    box-sizing: content-box;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    list-style: none;
    margin: 20;
    padding: 20;
    text-decoration: none;
    vertical-align: top;
} */

/* heading */

header {
    margin: 0 0 3em;
}

h1 {
    font: bold 100% sans-serif;
    letter-spacing: 0.5em;
    text-align: center;
    text-transform: uppercase;
}

header h1 {
    background: black;
    border-radius: 0.25em;
    color: white;
    margin: 0 0 1em;
    padding: 0.5em 0;
}

header address {
    float: left;
    font-size: 75%;
    font-style: normal;
    line-height: 1.25;
    margin: 0 1em 1em 0;
}

header address p {
    margin: 0 0 0.25em;
}

header span,
header img {
    display: block;
    float: right;
}

header span {
    margin: 0 0 1em 1em;
    max-height: 25%;
    max-width: 60%;
    position: relative;
}

header img {
    max-height: 50%;
    max-width: 50%;
}

header:after {
    clear: both;
    content: "";
    display: table;
}

/* article */

article,
article address,
table {
    margin: 0 0 3em;
}

article h1 {
    clip: rect(0 0 0 0);
    position: absolute;
}

article address {
    float: left;
    font-size: 125%;
    font-weight: bold;
}

article:after {
    clear: both;
    content: "";
    display: table;
}

/* table */

table {
    font-size: 75%;
    table-layout: auto;
    width: 100%;
}

table {
    border-collapse: separate;
    border-spacing: 2px;
}

th,
td {
    border-width: 1px;
    padding: 1em;
    position: relative;
    text-align: left;
}

th,
td {
    border-radius: 0.25em;
    border-style: solid;
}

th {
    background: #EEE;
    border-color: #BBB;
}

td {
    border-color: #DDD;
}

/* table firstTable */

table.firstTable {
    float: right;
    width: 45%;
    height: 50%;
}

table.firstTable:after {
    clear: both;
    content: "";
    display: table;
}

/* table firstTable */

table.firstTable th {
    width: 40%;
}

table.firstTable td {
    width: 60%;
}

form.invoice-container {
    font: 16px/1 'Open Sans', sans-serif;
    overflow: auto;
    padding: 0.5in;
}

form.invoice-container {
    background: #999;
    cursor: default;
}

form.invoice-container {
    box-sizing: border-box;
    height: 10in;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
    overflow: hidden;
    padding: 0.5in;
    width: 8.5in;
}

form#payment-form {
    box-sizing: border-box;
    height: auto!important;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
    overflow: hidden;
    padding: 0.5in;
    width: 8.5in;
}

form.invoice-container {
    background: #FFF;
    border-radius: 1px;
    box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
}



aside h1#notes {
    border: none;
    border-width: 0 0 1px;
    margin: 0 0 1em;
}

aside h1#notes {
    border-color: #999;
    border-bottom-style: solid;
    border-bottom-width: 10%;
    font: bold 100% sans-serif;
    letter-spacing: 0.5em;
    text-align: center;
    text-transform: uppercase;
}

.badge-success {
    background-color: green;
    font-size: 12px;
    padding: 5px;
    margin-right: 10px;
    text-align: center;
    border-radius: 2px;

}

.badge-error {
    background-color: rgb(187, 12, 12);
    font-size: 12px;
    padding: 5px;
    margin-right: 10px;
    text-align: center;
    border-radius: 2px;

}